<template>
    <div
        style="
            background: white;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999999;
            position: absolute;
            width: 100vw;
        "
    >
        <div
            class="px-4"
            style="max-width: 400px; text-align: center; font-weight: 500"
        >
            {{ $t('deprecated.heading') }}
            <a style="font-weight: 500" href="https://app.yachtside.mc">
                app.yachtside.mc
            </a>
            {{ $t('deprecated.explanation') }}
            <a
                class="btn btn-primary w-100 mt-4"
                href="https://app.yachtside.mc"
            >
                {{ $t('deprecated.goToLatestVersion') }}
            </a>
            <div class="dropdown" style="margin-top: 50px">
                <a
                    href=""
                    @click.prevent="switchLanguage()"
                    class="w-100 border-0 bg-white mb-3 text-primary"
                >
                    <i class="fa fa-globe mr-2"></i>
                    <span v-if="i18n.locale.value == 'fr'"> English </span>
                    <span v-else> Francais </span>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="#">English</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="#">Français</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
const i18n = useI18n();
// force push update

function switchLanguage() {
    if (i18n.locale.value == 'fr') {
        i18n.locale.value = 'en';
    } else {
        i18n.locale.value = 'fr';
    }
}
</script>
